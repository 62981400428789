import { instantMeiliSearch } from "@meilisearch/instant-meilisearch"
import favGenerix from './assets/favicon/favIconGenerix.png'
import favMonolith from './assets/favicon/favicon.ico'

export const siteDomain = window.location.hostname
const LangFix = e => e === 'gb' ? 'en' : e

const xCompanyIDs = {
    'localhost': '2',
    'setup.niki24.xyz': '2',
    'b2b.monolith-pt.com': '1',
    'b2b.monolith-uk.com': '2',
    'b2b.monolith-cy.com': '3',
    'b2b-generix.niki24.pt': '1',
    'b2b-demo.niki24.pt': '1',
}

const companyXTenants = {
    'localhost': 'monolith',
    'setup.niki24.xyz': 'monolith',
    'b2b.monolith-pt.com': 'monolith',
    'b2b.monolith-uk.com': 'monolith',
    'b2b.monolith-cy.com': 'monolith',
    'b2b-generix.niki24.pt': 'generix',
    'b2b-demo.niki24.pt': 'demo',
}

const companyTenants = {
    'localhost': 'localhost',
    'setup.niki24.xyz': 'monolith',
    'b2b.monolith-pt.com': 'monolith',
    'b2b.monolith-uk.com': 'monolith',
    'b2b.monolith-cy.com': 'monolith',
    'b2b-generix.niki24.pt': 'generix',
    'b2b-demo.niki24.pt': 'demo',
}

const companySuppliers = {
    'localhost': 'Monolith UK',
    'setup.niki24.xyz': 'Monolith UK',
    'b2b.monolith-pt.com': 'Monolith Ibérica',
    'b2b.monolith-uk.com': 'Monolith UK',
    'b2b.monolith-cy.com': 'Monolith CY',
    'b2b-generix.niki24.pt': 'GCP - Generix Group Portugal',
    'b2b-demo.niki24.pt': 'Demo Company',
}

const companyNames = {
    'localhost': 'Monolith UK B2B Webshop',
    'setup.niki24.xyz': 'Monolith UK B2B Webshop',
    'b2b.monolith-pt.com': 'Monolith Ibérica B2B Webshop',
    'b2b.monolith-uk.com': 'Monolith UK B2B Webshop',
    'b2b.monolith-cy.com': 'Monolith Cyprus B2B Webshop',
    'b2b-generix.niki24.pt': 'Generix Group Portugal B2B Webshop',
    'b2b-demo.niki24.pt': 'Demo Company B2B Webshop',
}

const companyUrlAddresses = {
    'localhost': "https://monolith-uk.com/new-customer-application-form/",
    'setup.niki24.xyz': "https://monolith-uk.com/new-customer-application-form/",
    'b2b.monolith-pt.com': "https://monolith-pt.com/",
    'b2b.monolith-uk.com': "https://monolith-uk.com/new-customer-application-form/",
    'b2b.monolith-cy.com': "https://monolith-cy.com/",
    'b2b-generix.niki24.pt': "https://b2b-generix.niki24.pt/",
    'b2b-demo.niki24.pt': "https://b2b-demo.niki24.pt/",
}

const companyCountryShorts = {
    'localhost': 'gb',
    'setup.niki24.xyz': 'gb',
    'b2b.monolith-pt.com': 'pt',
    'b2b.monolith-uk.com': 'gb',
    'b2b.monolith-cy.com': 'cy',
    'b2b-generix.niki24.pt': 'pt',
    'b2b-demo.niki24.pt': 'pt',
}

const pricelineIDsRecommendeds = {
    'localhost': '0KkzrXn5gamP2ypM',
    'setup.niki24.xyz': '0KkzrXn5gamP2ypM',
    'b2b.monolith-pt.com': 'O5D1YKnGOndeJ9qv',
    'b2b.monolith-uk.com': '0KkzrXn5gamP2ypM',
    'b2b.monolith-cy.com': '',
    'b2b-generix.niki24.pt': '0KkzrXn5gamP2ypM',
    'b2b-demo.niki24.pt': '0KkzrXn5gamP2ypM',
}

const bookletsTypeIDs = {
    'localhost': '38',
    'setup.niki24.xyz': '38',
    'b2b.monolith-pt.com': '38',
    'b2b.monolith-uk.com': '38',
    'b2b.monolith-cy.com': '38',
    'b2b-generix.niki24.pt': '38',
    'b2b-demo.niki24.pt': '38',
}

const catalogsTypeIDs = {
    'localhost': '39',
    'setup.niki24.xyz': '39',
    'b2b.monolith-pt.com': '39',
    'b2b.monolith-uk.com': '39',
    'b2b.monolith-cy.com': '39',
    'b2b-generix.niki24.pt': '39',
    'b2b-demo.niki24.pt': '39',
}

const feedbackPolicyIDs = {
    'localhost': 4,
    'setup.niki24.xyz': 4,
    'b2b.monolith-pt.com': 4,
    'b2b.monolith-uk.com': 4,
    'b2b.monolith-cy.com': 4,
    'b2b-generix.niki24.pt': 4,
    'b2b-demo.niki24.pt': 4,
}

export const languages = [
    { flag: 'gb', country: 'English', translateCountry: 'niki.b2b.english' },
    { flag: 'pt', country: 'Portugues', translateCountry: 'niki.b2b.portuguese' },
    { flag: 'de', country: 'Deutsche', translateCountry: 'niki.b2b.deutsche' },
    { flag: 'ru', country: 'Russian', translateCountry: 'niki.b2b.russian' },
    // {flag: 'es', country: 'Espanol', translateCountry: 'Espanol'},
    // {flag: 'it', country: 'Italiano', translateCountry: 'Italiano'},
    // {flag: 'fr', country: 'Francias', translateCountry: 'Francias'},
    // {flag: 'gr', country: 'GREEK', translateCountry: 'GREEK'},  
]

export const languagesArray = {
    'gb': 'niki.b2b.english',
    'pt': 'niki.b2b.portuguese',
    'de': 'niki.b2b.deutsche',
    'ru': 'niki.b2b.russian',
    // 'es': 'Espanol',
    // 'it': 'Italiano',
    // 'fr': 'Francias',
    // 'gr': 'GREEK', 
}

export const priceLineID = localStorage.getItem('priceline.id')
export const xCompanyID = xCompanyIDs[siteDomain]
export const companyName = companyNames[siteDomain]
export const companyCountryShort = companyCountryShorts[siteDomain]
export const pricelineIDRecommended = pricelineIDsRecommendeds[siteDomain]
export const bookletsTypeID = bookletsTypeIDs[siteDomain]
export const catalogsTypeID = catalogsTypeIDs[siteDomain]
export const feedbackPolicyID = feedbackPolicyIDs[siteDomain]
export const companySupplier = companySuppliers[siteDomain]
export const companyUrlAddress = companyUrlAddresses[siteDomain]
export const xTenant = companyXTenants[siteDomain]

export const meilisearchUrl = process.env.REACT_APP_MEILISEARCH_URL || 'https://guru.monolith-sys.com'
export const searchClient = instantMeiliSearch(
    meilisearchUrl,
    process.env.REACT_APP_MEILISEARCH_SECRET_KEY,
    {
        primaryKey: 'id',
        requestConfig: {
            headers: {
                'X-CompanyID': xCompanyID,
                'Accept-Language': LangFix(localStorage.getItem('locale')),
                'X-Tenant': xTenant,
                'Accept': 'application/json',
                'Authorization': `Bearer ${process.env.REACT_APP_MEILISEARCH_SECRET_KEY}`,
            },
            method: 'GET'
        },
    }
)

const companyColorMonolithred = {
    'localhost': '#9F0027',
    'setup.niki24.xyz': '#9F0027',
    'b2b.monolith-pt.com': '#9F0027',
    'b2b.monolith-uk.com': '#9F0027',
    'b2b.monolith-cy.com': '#9F0027',
    'b2b-generix.niki24.pt': '#055780',
    'b2b-demo.niki24.pt': '#055780',
};

const companyColorMonolithmain = {
    'localhost': '#fceaea',
    'setup.niki24.xyz': '#fceaea',
    'b2b.monolith-pt.com': '#fceaea',
    'b2b.monolith-uk.com': '#fceaea',
    'b2b.monolith-cy.com': '#fceaea',
    'b2b-generix.niki24.pt': '#b1dcf1',
    'b2b-demo.niki24.pt': '#b1dcf1',
};
export const currentMainColor = companyColorMonolithred[siteDomain]
export const currentLigthMainColor = companyColorMonolithmain[siteDomain]

document.documentElement.style.setProperty('--maincolor', currentMainColor);
document.documentElement.style.setProperty('--ligthmain', currentLigthMainColor);

const titleElement = document.querySelector('title');
titleElement.textContent = companyTenants[siteDomain];

const faviconElement = document.querySelector('link[rel="icon"]');
if (siteDomain === 'b2b-generix.niki24.pt') {
    if (faviconElement) {
        faviconElement.href = favGenerix
    }
} else {
    faviconElement.href = favMonolith
}