import { useEffect, useState } from 'react'

import {
    BrowserRouter as Router,
    Routes,
    Route
} from 'react-router-dom'

import { Header } from './header'
import { Footer } from './footer'
import { RecoverMail } from './components/loginComponents/RecoverMail'
import { LoginPhone } from './components/loginComponents/LoginPhone'
import { Login } from './pages/Login'
import { SelectAccount } from './components/loginComponents/SelectAccount'

import { MainPage } from './pages/MainPage'
import { AllProducts } from './pages/AllProducts'
import { Promotions } from './components/marketing/Promotions'
import { Brands } from './pages/Brands'
import { Contact } from './pages/Contact'
import { Marketing } from './pages/Marketing'
import { Account } from './pages/Account'
import { ProductCatalogs } from './components/marketing/ProductCatalogs'
import { MyShoppingCart } from './pages/MyShoppingCart'
import { Checkout } from './pages/Checkout'
import { Orders } from './pages/Orders'
import { OrderDetails } from './pages/OrderDetails'
import { ShoppingCarts } from './pages/ShoppingCarts'
import { ShoppingCart } from './pages/ShoppingCart'
import { Debts } from './pages/Debts'
import { Invoices } from './pages/Invoices'
import { ReturnProducts } from './pages/ReturnProducts'
import { Process } from './pages/Process'
import { DeliveryAddresses } from './pages/DeliveryAddresses'
import { ThankYou } from './pages/ThankYou'
import { NoMatch } from './staticpages/404'
import { Videos } from './pages/Videos'
import { Feedback } from './pages/Feedback'
import { SubmitFeedback } from './pages/SubmitFeedback'
import { FeedbackStatus } from './pages/FeedbackStatus'
import { FeedbackReceived } from './pages/FeedbackReceived'
import { Search } from './Search'
import { ReturnRegistered } from './pages/ReturnRegistered'
import { PrivacyPolicy } from './staticpages/PrivacyPolicy'
import { MandatoryDisclosures } from './staticpages/MandatoryDisclosures'
import { TermsSale } from './staticpages/TermsSale'
import { ProductPage } from './pages/ProductPage'
import { Token } from './Token'
import { PageMaintenance } from './staticpages/PageMaintenance'

!localStorage.getItem('locale') && localStorage.setItem('locale', 'gb')

const siteDomain = window.location.hostname
const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window
    return { width, height }
}

const App = () => {

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())
    const redirs = {
        'setup.niki24.xyz': 'mb2b.niki24.xyz',
        'b2b.monolith-pt.com': 'mb2b.monolith-pt.com',
        'b2b.monolith-uk.com': 'mb2b.monolith-uk.com',
        'b2b.monolith-cy.com': 'mb2b.monolith-cy.com',
        'b2b-generix.niki24.pt': 'mb2b-generix.niki24.pt',
        'b2b-demo.niki24.pt': 'mb2b-demo.niki24.pt',
    }

    useEffect(() => {
        const whereToRedir = `https://${redirs[siteDomain]}${window.location.pathname}${window.location.search}`
        setWindowDimensions(getWindowDimensions())
        if (windowDimensions.width <= 1024 && siteDomain !== 'localhost') window.location.href = whereToRedir
        // eslint-disable-next-line
    }, [windowDimensions.width])

    if (localStorage.getItem('enter.shop') && parseInt(localStorage.getItem('enter.shop')) === 1) {
        return (
            <>
                {process.env.REACT_APP_MANTAINECE_MODE
                    ? <Router>
                        <Routes>
                            <Route path="*" element={<PageMaintenance />} />
                        </Routes>
                        <div className="loader"></div>
                    </Router>
                    : <Router>
                        <Header />
                        <Routes>
                            <Route path="/token" element={<Token />} />
                            <Route path="/productpage/:id" element={<ProductPage />} />
                            {/* <Route path="/productpageold" element={<ProductPageOld />} /> */}
                            <Route path="/all-products" element={<AllProducts />} />
                            {/* <Route path="/all-products-old" element={<AllProductsOld />} /> */}
                            <Route path="/promotional-booklets" element={<Promotions />} />
                            <Route path="/product-catalogs" element={<ProductCatalogs />} />
                            <Route path="/brands" element={<Brands />} />
                            <Route path="/contact" element={<Contact />} />
                            <Route path="/marketing" element={<Marketing />} />
                            <Route path="/account" element={<Account />} />
                            <Route path="/myshoppingcart" element={<MyShoppingCart />} />
                            <Route path="/checkout" element={<Checkout />} />
                            <Route path="/orders" element={<Orders />} />
                            <Route path="/order/:id" element={<OrderDetails />} />
                            <Route path="/document/:id" element={<OrderDetails />} />
                            <Route path="/shoppingcarts" element={<ShoppingCarts />} />
                            <Route path="/shoppingcart/:id" element={<ShoppingCart />} />
                            <Route path="/debts" element={<Debts />} />
                            <Route path="/invoices" element={<Invoices />} />
                            <Route path="/returnproducts/:id" element={<ReturnProducts />} />
                            <Route path="/process/:id" element={<Process />} />
                            <Route path="/deliveryaddresses" element={<DeliveryAddresses />} />
                            <Route path="/thankyou" element={<ThankYou />} />
                            <Route path="/videos" element={<Videos />} />
                            <Route path="/feedback" element={<Feedback />} />
                            <Route path="/submit-feedback" element={<SubmitFeedback />} />
                            <Route path="/feedback-status/:id" element={<FeedbackStatus />} />
                            <Route path="/feedback-received/:id" element={<FeedbackReceived />} />
                            <Route path="/search" element={<Search />} />
                            <Route path="/returnregistered/:id" element={<ReturnRegistered />} />
                            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                            <Route path="/mandatory-disclosures" element={<MandatoryDisclosures />} />
                            <Route path="/terms-sale" element={<TermsSale />} />
                            <Route path="/" element={<MainPage />} />
                            <Route path="*" element={<NoMatch />} />
                        </Routes>
                        <Footer />
                        <div className="loader"></div>
                    </Router>
                }
            </>
        )
    } else {
        return (
            <Router>
                <Routes>
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/mandatory-disclosures" element={<MandatoryDisclosures />} />
                    <Route path="/select-account" element={<SelectAccount />} />
                    <Route path="/login-phone" element={<LoginPhone />} />
                    <Route path="/recover-mail" element={<RecoverMail />} />
                    <Route path="/recover-sms" element={<RecoverMail />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="*" element={<Login />} />
                </Routes>
            </Router>
        )
    }
}

export default App