import { useEffect, useRef, useState } from "react"

import { Product } from "./product/Product"
import { ProductLoadingPlaceholder } from './productlistplaceholder'
import { connectInfiniteHits } from 'react-instantsearch-dom'

export const CustomInfiniteHits = connectInfiniteHits(({ hits, hasMore, refineNext, productView, setClickFavorites }) => {    
    const favorites = localStorage.getItem('favorites')
    const histories =  localStorage.getItem('history.ids')   
    const loaderRef = useRef(null)
    const search = window.location.search

    const [isFetching, setIsFetching] = useState(false)
    const [isLoading, setIsoading] = useState(false)

    const handleScroll = () => {
        if (!hasMore || isFetching) return
        if (loaderRef.current && loaderRef.current.getBoundingClientRect().top <= window.innerHeight*3) {
            setIsoading(true)
            setIsFetching(true)
        }
    }
  
    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
        // eslint-disable-next-line
    }, [hits, isFetching, hasMore])

    useEffect(() => {
        if (document.querySelector('.product_in_listing')) {
            document.querySelector('.products_on_page').innerText = hits.length
            setIsoading(false)
                //document.querySelectorAll('.product_in_listing').length + ProductsPerPage
        }
    }, [hits.length])

    useEffect(() => {
        if (!isFetching) return
        refineNext()
        setIsFetching(false)
    }, [isFetching, refineNext])
  
    return (
        <div>
            <div className="products_listing">
                {hits.map(hit => {
                    if(search && (search.includes('favorites') || search.includes('purchased'))){
                        if (search.includes('favorites')) {
                            return favorites.includes(hit.id) && <Product {...hit} productView={productView} setClickFavorites={setClickFavorites} />
                        } else {
                            return histories.includes(hit.id) && <Product {...hit} productView={productView} setClickFavorites={setClickFavorites} />
                        }
                    } else {
                        return <Product {...hit} productView={productView} setClickFavorites={setClickFavorites} />
                    } 
                }  
                )}
            </div>
            <div ref={loaderRef}></div>
            {isLoading && <ProductLoadingPlaceholder />}
        </div>
    )
})

